// ##############################
// // // TasksCard styles
// #############################

import {
    card,
    cardHeader,
    defaultFont,
    primaryBoxShadow
} from '../material-dashboard-react.js';

const tasksCardStyle = theme => ({
    card: {
        ...card,
        overflow: 'visible'
    },
    cardHeader: {
        flex: 'none',
        ...cardHeader,
        ...defaultFont,
        background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
        ...primaryBoxShadow
    },
    cardTitle: {
        ...defaultFont,
        float: 'left',
        fontWeight: '500',
        padding: '10px 10px 10px 0',
        lineHeight: '24px',
        fontSize: '14px',
        color: '#FFFFFF'
    },
    tabWrapper: {
        width: 'auto',
        display: 'inline-flex',
        alignItems: 'inherit',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex'
        }
    },
    tabIcon: {
        float: 'left',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-2px'
        }
    },
    displayNone: {
        display: 'none'
    },
    labelIcon: {
        height: '44px',
        width: '110px',
        minWidth: '72px',
        paddingLeft: '14px',
        borderRadius: '3px'
    },
    tabsContainer: {
        marginTop: '4px',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            display: 'grid'
        }
    },
    tabs: {
        width: '110px',
        minWidth: '70px',
        paddingLeft: '12px'
    },
    cardHeaderContent: {
        flex: 'none'
    },
    label: {
        lineHeight: '19px',
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: '500',
        marginLeft: '-10px'
    },
    textColorInheritSelected: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        transition: 'background-color .4s'
    }
});

export default tasksCardStyle;
